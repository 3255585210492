<template>
  <div class="releaseRequirements">
    <div class="rR-title">发布需求</div>
    <div class="rR-form">
      <el-form inline :model="formData" label-width="120px">
        <div class="fc-box">
          <el-form-item>
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>需求标题</span>
              </div>
            </template>
            <el-input
              v-model="formData.title"
              placeholder="请输入需求标题"
            ></el-input>
          </el-form-item>
          <el-form-item class="fc-ditem">
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>需求类型</span>
              </div>
            </template>
            <el-select
              v-model="formData.serviceType"
              placeholder="请选择需求品种"
              @change="changeServiceType"
            >
              <el-option
                v-for="item in serviceTypeArray"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select
              v-model="formData.serviceVariety"
              placeholder="请选择需求类型"
            >
              <el-option
                v-for="item in serviceVarietyList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="fc-box">
          <el-form-item>
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>需求价格类型</span>
              </div>
            </template>
            <el-select
              v-model="formData.budgetType"
              placeholder="请选择需求状态"
            >
              <el-option value="0" label="预算价格"></el-option>
              <el-option value="1" label="面议"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>需求数量</span>
              </div>
            </template>
            <el-input
              maxlength="12"
              v-model="formData.quantity"
              placeholder="请输入需求数量"
            ></el-input>
          </el-form-item>
        </div>
        <div class="fc-box">
          <el-form-item>
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>需求联系电话</span>
              </div>
            </template>
            <el-input
              v-model="formData.phone"
              placeholder="请输入需求联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item v-show="formData.budgetType != 1" class="fc-ditem">
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>需求价格</span>
              </div>
            </template>
            <el-input
              maxlength="12"
              v-model.number="formData.budget"
              placeholder="请输入需求价格"
            ></el-input>
            <el-select v-model="formData.unitId" placeholder="请选择单位">
              <el-option
                v-for="item in unitList"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="fc-box">
          <el-form-item>
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>服务状态</span>
              </div>
            </template>
            <el-select
              v-model="formData.putawayStatus"
              placeholder="请选择上架状态"
            >
              <el-option value="0" label="下架"></el-option>
              <el-option value="1" label="上架"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="fc-ditem">
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>需求起止时间</span>
              </div>
            </template>
            <el-date-picker
              v-model="dataPicker"
              @change="changePicker"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="selectContent">
          <el-form-item>
            <div class="lb-slot">
              <span style="color: red; margin-right: 3px">*</span>
              <span>需求地址</span>
            </div>
            <div class="sl-box">
              <el-select
                @change="dealPlace(1)"
                v-model="formData.cityCode"
                placeholder="市"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option> </el-select
              ><el-select
                @change="dealPlace(2)"
                v-model="formData.countyCode"
                placeholder="区/县"
              >
                <el-option
                  v-for="item in areaList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option> </el-select
              ><el-select
                @change="dealPlace(3)"
                v-model="formData.townCode"
                placeholder="乡/镇"
              >
                <el-option
                  v-for="item in streetCity"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option> </el-select
              ><el-select v-model="formData.villageCode" placeholder="村">
                <el-option
                  v-for="item in villageList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>
        </div>
        <div>
          <el-form-item>
            <template slot="label">
              <div class="lb-slot">
                <span style="color: red; margin-right: 3px">*</span>
                <span>需求描述</span>
              </div>
            </template>
            <editor v-model="demandDetail" :min-height="240" />
          </el-form-item>
        </div>
        <div class="fc-box">
          <el-form-item label="需求图片">
            <imageUpload :limit="1" v-model="formData.imgUrl" />
          </el-form-item>
        </div>
        <div class="footBtn">
          <el-button class="el-icon-check" @click="checkRules" type="success"
            >提交</el-button
          >
          <el-button class="el-icon-refresh" type="danger" @click="resetForm"
            >重置</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import {
  getPlace,
  getServiceVarietys,
  getServiceTypes,
  getDictData,
  OmsDemand,
} from "@/api/serviechall";
import Editor from "@/components/Editor";
import imageUpload from "@/components/imageUpload";
import { Base64 } from "js-base64";

export default {
  components: { Editor, imageUpload },
  data() {
    return {
      formData: {
        title: "", //需求标题
        serviceType: "", //需求品种
        serviceVariety: "", //需求类型
        budget: "", //预算
        quantity: "", //需求数量
        startTime: "", //需求开始时间
        endTime: "", //需求结束时间
        provinceCode: "43",
        townCode: "",
        cityCode: "",
        countyCode: "",
        villageCode: "",
        budget: "", //需求描述
        imgUrl: "", //需求图片
        unitId: "", //单位
        demandDetail: "", //需求描述
        budgetType: 0,
        province: "湖南省",
        village: "",
        town: "",
        county: "",
        city: "",
        serviceTypeName: "",
        serviceVarietyName: "",
        unitName: "",
        budgetType: "",
        putawayStatus: "",
        phone: "",
      },
      demandDetail: "",
      areaList: [],
      cityList: [],
      streetCity: [],
      villageList: [],
      serviceVarietyList: [],
      serviceTypeArray: [],
      dataPicker: [],
      unitList: [],
    };
  },
  methods: {
    getPlace() {
      const { provinceCode, cityCode, countyCode, townCode } = this.formData;
      let query = {
        areaCode: countyCode,
        provinceCode,
        cityCode,
        streetCode: townCode,
      };
      getPlace(query).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          this.cityList = data.cityList;
          this.areaList = data.areaList ? data.areaList : [];
          this.streetCity = data.streetList ? data.streetList : [];
          this.villageList = data.villageList ? data.villageList : [];
        }
      });
    },
    dealPlace(code) {
      if (code == 1) {
        this.formData.countyCode = "";
        this.formData.townCode = "";
        this.formData.villageCode = "";
      } else if (code == 2) {
        this.formData.townCode = "";
        this.formData.villageCode = "";
      } else if (code == 3) {
        this.formData.villageCode = "";
      }
      this.getPlace();
    },
    getAreaStr() {
      this.cityList.map((item) => {
        if (item.code == this.formData.cityCode) {
          this.formData.city = item.name;
          return;
        }
      });
      this.areaList.map((item) => {
        if (item.code == this.formData.countyCode) {
          this.formData.county = item.name;
          return;
        }
      });
      this.streetCity.map((item) => {
        if (item.code == this.formData.townCode) {
          this.formData.town = item.name;
          return;
        }
      });
      this.villageList.map((item) => {
        if (item.code == this.formData.villageCode) {
          this.formData.village = item.name;
          return;
        }
      });
      this.serviceTypeArray.map((item) => {
        if (item.id == this.formData.serviceType) {
          this.formData.serviceTypeName = item.name;
          return;
        }
      });
      this.serviceVarietyList.map((item) => {
        if (item.id == this.formData.serviceVariety) {
          this.formData.serviceVarietyName = item.name;
          return;
        }
      });
      this.unitList.map((item) => {
        if (item.dictValue == this.formData.unitId) {
          this.formData.unitName = item.dictLabel;
          return;
        }
      });
    },
    changeServiceType() {
      this.formData.serviceVariety = "";
      this.serviceVarietyList = [];
      getServiceVarietys([this.formData.serviceType]).then((res) => {
        const { data, code } = res;
        if (code === 200) {
          if (data) {
            this.serviceVarietyList = data;
          }
        }
      });
    },
    getServiceTypes() {
      getServiceTypes([]).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          this.serviceTypeArray = data || [];
        }
      });
    },
    changePicker(val) {
      if (val) {
        this.formData.startTime = val[0];
        this.formData.endTime = val[1];
      } else {
        this.formData.startTime = "";
        this.formData.endTime = "";
      }
    },
    resetForm() {
      Object.assign(this._data, this.$options.data());
      this.getServiceTypes();
      this.getPlace();
      this.getDictData();
    },
    getDictData() {
      getDictData({ dictType: "sys_oms_unit_name_simple" }).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          this.unitList = data;
        }
      });
    },
    checkRules() {
      const {
        title,
        serviceType,
        serviceVariety,
        putawayStatus,
        cityCode,
        startTime,
        endTime,
        quantity,
        budgetType,
        budget,
        unitId,
        phone,
      } = this.formData;
      let phoneReg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!title) {
        this.$message.error("请选择需求标题");
        return;
      } else if (!serviceType) {
        this.$message.error("请选择需求品种");
        return;
      } else if (!serviceVariety) {
        this.$message.error("请选择需求类型");
        return;
      } else if (!budgetType) {
        this.$message.error("请选择需求价格类型");
        return;
      } else if (!quantity) {
        this.$message.error("请选择需求数量");
        return;
      } else if (!phoneReg.test(phone)) {
        this.$message.error("联系电话不正确，请重新输入");
        return;
      }
      if (budgetType == 0) {
        if (!budget) {
          this.$message.error("请输入需求价格");
          return;
        } else if (!unitId) {
          this.$message.error("请选择单位");
          return;
        }
      }
      if (!putawayStatus) {
        this.$message.error("请选择上架状态");
        return;
      } else if (!startTime) {
        this.$message.error("请选择需求开始时间");
        return;
      } else if (!endTime) {
        this.$message.error("请选择需求结束时间");
        return;
      } else if (!cityCode) {
        this.$message.error("请选择需求地址");
        return;
      } else if (!this.demandDetail) {
        this.$message.error("请输入需求描述");
        return;
      }
      this.formData.demandDetail = window.btoa(
        unescape(encodeURIComponent(this.demandDetail))
      );
      this.getAreaStr();
      this.OmsDemand();
    },
    OmsDemand() {
      OmsDemand(this.formData).then((res) => {
        const { code, msg } = res;
        if (code === 200) {
          this.$message.success(msg || "发布需求成功");
          this.$router.push("/");
        } else {
          this.$message.error(msg || "发布需求失败");
        }
      });
    },
  },
  created() {
    this.getServiceTypes();
    this.getPlace();
    this.getDictData();
  },
};
</script>
<style lang="scss" scoped>
.releaseRequirements {
  width: 1396px;
  margin: 20px auto 0;
  padding: 20px;
  box-sizing: border-box;
  .rR-title {
    background: url(../../assets/image/rR1.png) no-repeat center top;
    height: 58px;
    line-height: 66px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    position: relative;
  }
  .rR-form {
    width: 1000px;
    margin: auto;
    padding-top: 20px;
    border-radius: 5px;
    padding: 10px;
    .fc-box {
      display: flex;
      justify-content: space-between;
      /deep/ .el-form-item__label {
        margin-right: 0px;
      }
      .fc-ditem {
        display: flex;
        /deep/ .el-form-item__content {
          display: flex;
          justify-content: space-between;
          width: 320px;
        }
        /deep/ .el-input--small .el-input__inner {
          width: 150px !important;
        }
        /deep/ .el-input--small {
          width: 150px;
        }
      }
    }
    .footBtn {
      display: flex;
      width: 100%;
      justify-content: center;
      /deep/ .el-button {
        height: 38px;
        line-height: 38px;
        padding: 0 18px;
        color: #fff;
        font-size: 14px;
      }
    }
    .selectContent {
      /deep/ .el-form-item__content {
        width: 1000px;
        display: flex;
      }
      /deep/ .el-input--small .el-input__inner {
        width: 210px !important;
      }
      .sl-box {
        width: 870px;
        justify-content: space-between;
        display: flex;
      }
    }
    .lb-slot {
      display: flex;
      justify-content: flex-end;
      width: 120px;
      box-sizing: border-box;
      padding-right: 12px;
      color: #606266;
    }
    /deep/ .el-input--small .el-input__inner {
      width: 320px;
      height: 36px;
      margin-right: 0px;
    }
    /deep/ .el-upload-dragger {
      width: 140px;
      height: 100px;
    }
    /deep/ .el-upload-dragger .el-icon-upload {
      margin: 6px 0 0 0;
    }
  }
}
</style>